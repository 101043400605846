@tailwind base;
@tailwind components;
@tailwind utilities;

.ql-container {
  font-size: inherit;
}
.ql-container .ql-editor {
  padding: 0;
}

.ql-editor.ql-blank::before {
  color: #9ca3af;
  left: 0;
}
.ql-editor.ql-blank:focus::before {
  content: "";
}
